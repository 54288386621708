const InvoiceContent = `
<p>
I embarked on a journey to develop an Invoice/Quotation Generator tool to aid businesses in generating and sending invoices. This tool was implemented using ReactJs for the front-end and NodeJS for the back-end, ensuring a seamless user experience.
</p>

<p>
A significant challenge for businesses is managing and sending invoices efficiently. The objective was clear: design and implement an intuitive invoice generator that not only creates professional invoices but also offers the capability to send these invoices directly via email.
</p>

<p>
Utilizing agile software development methodologies, I created a robust solution. The generator leverages react-pdf for creating PDF invoices and nodemailer to handle email delivery. This ensures that businesses can generate and send invoices with ease, enhancing their operational efficiency.
</p>

<p>
Key features of the application include:
<ul>
<li>User-friendly interface for creating invoices</li>
<li>PDF generation using react-pdf</li>
<li>Email sending functionality powered by nodemailer</li>
<li>Seamless integration for sending invoices to registered email addresses</li>
</ul>
</p>

<p>
This project required not just technical prowess but also a deep understanding of business needs and effective communication with stakeholders to ensure the tool met their requirements.
</p>

<p>
For a closer look into the technicalities and codebase, you can visit the repository: <a href="https://github.com/bluesoltech/Invoice-bs" style="color: #00FF00; text-decoration: underline;">GitHub</a>.
</p>
`;

export default InvoiceContent;
