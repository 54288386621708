const ResumeContent = `
<pre>
If you want a pdf copy, you can visit the link <a href="https://coffee-merilee-66.tiiny.site" style="color: #00FF00; text-decoration: underline;">here</a>.

Kunal Singh

EDUCATION
Indian Institute of Information Technology, Guwahati
Bachelors of Technology in Computer Science Engineering
November 2020-May 2024
● Full Stack Web Developer | E-cell Head (2022-2023)


SKILLS & INTERESTS

Languages: C/C++,Java, JavaScript
Technologies: Node.js, Express.js, React.js, Redux, TailwindCSS, Git, NextJS, AWS, Linux
Databases: SQL, MongoDB


WORK EXPERIENCE

Magitech Innovision LLP, Guwahati
Position: Software Engineer Intern | Dec 2023 - June 2023
● Developed a college management system for more than 500 users.
● Engaged in web-application development and created interactive websites
  using React.js, Redux and Node.js
● Implemented OOPs principles to streamline development, reducing software bugs

Bitscape, Ahmedabad
Position: Software Developer Intern | May 2023 - July 2023
● Led front-end enhancements in React, increasing page load speed by 15%.
● Developed a Python Bot to fetch Option Chain Data from 2019-2023.


PROJECT EXPERIENCE

A Movie Recommendation Engine
Short Description: ML Model + Live update on software
Tech Stacks: ReactJs, NodeJS, Flask
● Implemented a machine learning (ML) model to recommend movies based on user
  ratings of 10,000 different users.
● Provided live updates to enhance software functionality within 2 minutes
  of interactions
● Repository Link: <a href="https://github.com/the-osiris/arrow" style="color: #00FF00; text-decoration: underline;">here</a>

Invoice/Quotation Generator 
Short Description: Download your Invoice or Send Invoice via mail
Tech Stacks: ReactJs, NodeJS
● Implemented Invoice generator tool using react-pdf and nodemailer to send mail.
● Developed for ease to businesses for generating invoices and send invoice
  over registered email.
● Repository Link: <a href="https://github.com/bluesoltech/Invoice-bs" style="color: #00FF00; text-decoration: underline;">here</a>

Option Trading Bot
Short Description: Historical data retriever and SMA Buy and Sell Point Marker
Tech Stacks: Pandas, Python
● Leveraged data analysis and fetched every 5 min option chain data from
  Opstra platform (2020 - 2023).
● Repository Link: <a href="https://github.com/the-osiris/Shite" style="color: #00FF00; text-decoration: underline;">here</a>

A Chat Application
Short Description: For real-time communication between friends
Tech Stacks: Socket.io, Node.js, React.js
● Designed and built a web application for real-time chatting with friends and
  it takes less than 1 second to send a text.
● Repository Link: <a href="https://github.com/the-osiris/Chat-App" style="color: #00FF00; text-decoration: underline;">here</a>

Time-Table Extension
Short Description: Live Time-Table Scrapper for Students
Tech Stacks: Pupeteer, JavaScript
● Developed a Chrome extension to display a live class timetable of Codetantra
  (an online teaching platform) used by 50+ students daily.
● Repository Link: <a href="https://github.com/the-osiris/class-alarm" style="color: #00FF00; text-decoration: underline;">here</a>


</pre>
`;

export default ResumeContent;