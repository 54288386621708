const TtextContent = `
<p>
In a bid to improve the accessibility and usability of Codetantra, an online teaching platform, I developed a Chrome extension to display a live class timetable. This tool has been instrumental for over 50 students daily, simplifying their class management experience.
</p>

<p>
A common issue faced by students was the lack of a centralized and easily accessible timetable. This often led to confusion and missed classes. My objective was clear: to design and implement a live timetable scrapper that updates in real-time, ensuring students always have the latest schedule at their fingertips.
</p>

<p>
Leveraging agile development methodologies, I created a robust solution using Puppeteer and JavaScript. The extension seamlessly scrapes the timetable from Codetantra and displays it in an intuitive and user-friendly format. This ensured that students could stay organized and manage their time effectively.
</p>

<p>
Key features of the extension include:
<ul>
<li>Real-time updates of the class timetable</li>
<li>Easy-to-use interface integrated into the Chrome browser</li>
<li>Notification alerts for upcoming classes</li>
<li>Minimal resource consumption to ensure smooth browser performance</li>
</ul>
</p>

<p>
This project required not just technical skills but also an understanding of user needs and effective communication with stakeholders. It was a rewarding experience that demonstrated the impact of thoughtful software design on user convenience.
</p>

<p>
For a closer look into the technical details and codebase, you can visit the repository: <a href="https://github.com/the-osiris/class-alarm" style="color: #00FF00; text-decoration: underline;">here</a>.
</p>
`;

export default TtextContent;
