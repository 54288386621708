const bioContent = `
<p>I am a problem-solver more than a coder. Building Barvipass currently.</p>

<p>I have developed a wide variety of skills from my previous internship, and academic studies. During my undergraduate studies, I had the opportunity to work on several projects that allowed me to develop my programming skills and gain a deeper understanding of software development. I also had the opportunity to intern at a startup company, where I learned the importance of teamwork and effective communication in the software development process.</p>

<p>Currently, I am looking for opportunities in software engineering. In my spare time, I enjoy solving coding challenges to further enhance my skills and stay up-to-date with the latest technologies. I am currently building BARVIPASS, It is a finance startup (will list more about this in future).</p>

<p>Skills Include:</p>
<ul>
  <li>Languages: C/C++,Java, JavaScript</li>
  <li>Technologies: Node.js, Express.js, React.js, Redux, TailwindCSS, Git, NextJS, AWS, Linux</li>
  <li>Databases: SQL, MongoDB</li>
  <li>Coursework: Data Structures, Algorithm, Object-Oriented Programming, Operating Systems, Computer Networks</li>
</ul>
`;

export default bioContent;
