import React, { useState, useEffect } from 'react';
import ArrowModalContent from './PageContent/ArrowContent';
import InvoiceModalContent from './PageContent/InvoiceContent';
import ChatModalContent from './PageContent/ChatContent';
import TtextModalContent from './PageContent/TtextContent';
import TradingModalContent from './PageContent/TradingContent';
// import robotRadarImage2 from './PageContent/radarGUI.png';

const ModalComponent = ({ isOpen, onClose, modalText, images = null }) => {
    const [visibleText, setVisibleText] = useState('');
    const [typingIndex, setTypingIndex] = useState(0);
    const typingSpeed = 8;

    {images && images.map((img, idx) => (
        <img key={idx} src={img} alt={`Modal Image ${idx + 1}`} />
    ))}

    useEffect(() => {
        let intervalId;

        if (isOpen) {
            intervalId = setInterval(() => {
                setVisibleText(prevText => {
                    const nextChar = modalText[typingIndex];
                    return prevText + nextChar;
                });

                setTypingIndex(prevIndex => prevIndex + 1);
            }, typingSpeed);
        }

        return () => clearInterval(intervalId);
    }, [isOpen, typingIndex]);

    useEffect(() => {
        if (!isOpen) {
            setVisibleText('');
            setTypingIndex(0);
        }
    }, [isOpen]);

    useEffect(() => {
        if (visibleText.endsWith('undefined')) {
            setVisibleText(prevText => prevText.replace(/undefined$/g, ''));
        }
    }, [visibleText]);

    return (
        <div className={isOpen ? 'modal open' : 'modal'}>
            <div className="modal-content">
                <span className="close" onClick={onClose}>
                    &times;
                </span>
                <div dangerouslySetInnerHTML={{ __html: visibleText }}></div>
                {images && images.map((img, idx) => (
                    <img key={idx} src={img} alt={`Modal Image ${idx + 1}`} />
                ))}
            </div>
        </div>
    );
};

// const RobotRadarModal = (props) => <ModalComponent {...props} modalText={RobotRadarModalContent} images={[robotRadarImage2]} />;
const ArrowModal = (props) => (
  <ModalComponent {...props} modalText={ArrowModalContent}/>
);
const InvoiceModal = (props) => (
  <ModalComponent {...props} modalText={InvoiceModalContent} />
);
const ChatModal = (props) => (
  <ModalComponent {...props} modalText={ChatModalContent} />
);
const TradingModal = (props) => (
  <ModalComponent {...props} modalText={TradingModalContent} />
);
const TtextModal = (props) => (
  <ModalComponent {...props} modalText={TtextModalContent} />
);


export { ArrowModal, InvoiceModal, ChatModal, TradingModal, TtextModal };