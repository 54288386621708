import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Components/Page Layout/Layout";
import {
  ArrowModal, InvoiceModal, TtextModal, TradingModal, ChatModal
} from "../Components/Project Page/ProjectsModal";

const Projects = () => {
  const navigate = useNavigate();
  const [isClosing, setIsClosing] = useState(false);
  const [isArrowModalOpen, setIsArrowModalOpen] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [isTradingModalOpen, setIsTradingModalOpen] = useState(false);
  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [isTimeTableModalOpen, setIsTimeTableModalOpen] = useState(false);


  const closeModal = (modalType) => {
    if (isClosing) return;

    setIsClosing(true);
    setTimeout(() => {
      switch (modalType) {
        case "arrow":
          setIsArrowModalOpen(false);
          break;
        case "invoice":
          setIsInvoiceModalOpen(false);
          break;
        case "trading":
          setIsTradingModalOpen(false);
          break;
        case "chat":
          setIsChatModalOpen(false);
          break;
        case "ttext":
          setIsTimeTableModalOpen(false);
          break;
      }
      setIsClosing(false);
    }, 1500);
  };

  const commands = {
    ls: {
      description: "List all projects on the page",
      fn: function () {
        return "Here are the project names. Enter a name to learn more: \nArrow\nInvoiceGen\nOptTradingBot\nChatApp\nTimeTableExt";
      },
    },

    about: {
      description: "Go to About page",
      fn: function () {
        navigate("/about");
        return "Redirecting to About page...";
      },
    },

    projects: {
      description: "You are here",
      fn: function () {
        return "You are already at the projects page";
      },
    },

    cd: {
      description: "Go to Main page",
      fn: function () {
        setTimeout(function () {
          navigate("/");
        }, 0);
        return "Redirecting to Main page...";
      },
    },

    Arrow: {
      description: "Movie Recommendation Engine",
      fn: function () {
        setTimeout(function () {
          setIsArrowModalOpen(true);
        }, 600);
        return "Hold your horses...";
      },
    },
    InvoiceGen: {
      description: "Invoice/Quotation Generator",
      fn: function () {
        setTimeout(function () {
          setIsInvoiceModalOpen(true);
        }, 600);
        return "Hold your horses...";
      },
    },
    OptTradingBot: {
      description: "Data retriever and SMA point marker",
      fn: function () {
        setTimeout(function () {
          setIsTradingModalOpen(true);
        }, 600);
        return "Hold your horses...";
      },
    },
    ChatApp: {
      description: "Real time chat communication like whatsapp",
      fn: function () {
        setTimeout(function () {
          setIsChatModalOpen(true);
        }, 600);
        return "Hold your horses...";
      },
    },
    TimeTableExt: {
      description: "Scrapping Time-Table data from live class",
      fn: function () {
        setTimeout(function () {
          setIsTimeTableModalOpen(true);
        }, 600);
        return "Hold your horses...";
      },
    },
  };

  return (
    <Layout
      commands={commands}
      welcomeMessage="Ennter command 'ls' to see some cool stuff I built. Use 'cd' to go back to the main page."
    >
      {isArrowModalOpen && !isClosing && (
        <ArrowModal
          isOpen={isArrowModalOpen}
          onClose={() => closeModal("arrow")}
        />
      )}
      {isInvoiceModalOpen && !isClosing && (
        <InvoiceModal
          isOpen={isInvoiceModalOpen}
          onClose={() => closeModal("invoice")}
        />
      )}
      {isTradingModalOpen && !isClosing && (
        <TradingModal
          isOpen={isTradingModalOpen}
          onClose={() => closeModal("trading")}
        />
      )}
      {isChatModalOpen && !isClosing && (
        <ChatModal
          isOpen={isChatModalOpen}
          onClose={() => closeModal("chat")}
        />
      )}
      {isTimeTableModalOpen && !isClosing && (
        <TtextModal
          isOpen={isTimeTableModalOpen}
          onClose={() => closeModal("ttext")}
        />
      )}
    </Layout>
  );
};

export default Projects;
